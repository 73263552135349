import { calculateArea } from 'helpers/StorageAreaCalculator';
import { parseIsoDate, daysPerMonth } from 'helpers/DateHelper';
import rentalTypes from 'enums/rentalTypes';
import { differenceInDays } from 'date-fns';
import paymentRecurrences from 'enums/paymentRecurrences';
import purchaseItemTypes from 'enums/purchaseItemTypes';
import vatModes from 'enums/vatModes';
import organizationTypes from 'enums/organizationTypes';
import { roundAmount, calculateVat } from 'helpers/MonetaryHelper';
import { calculateSum } from 'helpers/ArrayHelper';
import { onlyPeriodBookingsAllowed } from 'helpers/StorageSiteHelper';
import storageGroupCategories from 'enums/storageGroupCategories';
import displayPriceModes from 'enums/displayPriceModes';
import { createRoundingCorrectionPurchaseItem } from 'logic/paymentLogic';

export const MIN_SUBSCRIPTION_BOOKING_LENGTH = 60;

export const calculateDaysInPeriod = values => {
    const { startDate, endDate } = values;
    if (!startDate) {
        return undefined;
    }

    const subscriptionBooking = !endDate;
    if (subscriptionBooking) {
        return undefined;
    }
    if (!endDate) {
        return undefined;
    }
    return differenceInDays(endDate, startDate) + 1;
};

export const calculatePriceForPeriod = (storageSite, storageGroup, values, appContext) => {
    const { startDate, endDate, width, length, tenantOrganizationType = organizationTypes.private.key } = values;
    const isSubscriptionBooking = !endDate && !onlyPeriodBookingsAllowed(storageSite);
    const days = calculateDaysInPeriod(values);

    if (!startDate || (!isSubscriptionBooking && !endDate)) {
        return {
            subscription: isSubscriptionBooking,
            currency: storageGroup.currency
        };
    }

    let pricePerMonth;
    if (storageGroup.rentalType === rentalTypes.wholeSite.key || storageGroup.rentalType === rentalTypes.fixedArea.key) {
        pricePerMonth = storageGroup.pricePerStorage;
    } else {
        if (!(width > 0 && length > 0)) {
            return {
                subscription: isSubscriptionBooking,
                days,
                currency: storageGroup.currency
            };
        }
        const areaCalculation = calculateArea(length, width, storageGroupCategories[storageGroup.category]);
        pricePerMonth = storageGroup.pricePerAreaUnit * areaCalculation.adjustedArea;
    }
    const pricePerDay = pricePerMonth / daysPerMonth;

    let price = isSubscriptionBooking
        ? pricePerMonth
        : roundAmount(days * pricePerDay, appContext);
    if(!price && price !== 0) {
        price = undefined;
    }

    return {
        subscription: isSubscriptionBooking,
        days,
        ...getPriceInfo(price, appContext, storageGroup, tenantOrganizationType)
    };
};

export const calculateMinBookingLengthForSubscriptionBooking = ({minBookingLength}) => {
    return minBookingLength > MIN_SUBSCRIPTION_BOOKING_LENGTH ? minBookingLength : MIN_SUBSCRIPTION_BOOKING_LENGTH;
};

export const getPriceInfo = (priceExcludingVat, appContext, object /* storageGroup or bookingItem */, tenantOrganizationType) =>
    getPriceInfoHelper(priceExcludingVat, appContext, object.vatMode ?? vatModes.eligibleForVat.key, object.vatRate, tenantOrganizationType);

const getPriceInfoHelper = (priceExcludingVat, appContext, vatMode, vatRate, tenantOrganizationType) => {
    if(priceExcludingVat === undefined) {
        return {};
    }

    let displayPrice;
    let priceIncludingVat;
    const roundedPriceExcludingVat = roundAmount(priceExcludingVat, appContext);
    switch(vatMode) {
        case vatModes.notEligibleForVat.key:
            priceIncludingVat = roundedPriceExcludingVat;
            displayPrice = roundAmount(priceIncludingVat, appContext, true);
            return {
                displayPrice,
                priceExcludingVat: roundedPriceExcludingVat,
                priceIncludingVat,
                roundingCorrection: displayPrice - priceIncludingVat,
                currency: appContext.currency.code,
                vat: 0,
                vatRate: 0
            };

        case vatModes.eligibleForVat.key:
            priceIncludingVat = roundedPriceExcludingVat + calculateVat(roundedPriceExcludingVat, vatRate, appContext);
            const roundedPriceIncludingVat = roundAmount(priceIncludingVat, appContext, true);
            displayPrice = appContext.displayPriceMode === displayPriceModes.excludingVat.key
                ? roundAmount(roundedPriceExcludingVat, appContext, true)
                : roundAmount(priceIncludingVat, appContext, true);
            return {
                displayPrice,
                priceExcludingVat: roundedPriceExcludingVat,
                priceIncludingVat,
                roundingCorrection: roundedPriceIncludingVat - priceIncludingVat,
                currency: appContext.currency.code,
                vat: priceIncludingVat - roundedPriceExcludingVat,
                vatRate: vatRate ?? 0
            };

        case vatModes.businessesOnlyEligibleForVat.key:
            const adjustedVatMode = tenantOrganizationType === organizationTypes.business.key
                ? vatModes.eligibleForVat.key
                : vatModes.notEligibleForVat.key;
            return getPriceInfoHelper(priceExcludingVat, appContext, adjustedVatMode, vatRate);

        default:
            throw new Error(`Unknown VAT mode: '${JSON.stringify(vatMode)}'`);
    }
};

export const storageSiteHasBusinessesOnlyEligibleForVat = (storageSite, category) =>
    storageSite.storageGroups.filter(sg => sg.vatMode === vatModes.businessesOnlyEligibleForVat.key && (!category || sg.category === category.key)).length > 0;

export const createBookingPriceInfo = (booking, bookingItems, appContext, additive) => ({
    oneTime: createOneTimePriceInfo(booking, bookingItems, appContext, additive),
    perMonth: createPerMonthPriceInfo(booking, bookingItems, appContext, additive),
    wholePeriod: createWholePeriodPriceInfo(booking, bookingItems, appContext, additive)
});

const createOneTimePriceInfo = (booking, bookingItems, appContext, additive) => {
    const oneTimeBookingItems = bookingItems.filter(o => o.paymentRecurrence === paymentRecurrences.oneTime.key);
    const oneTimePurchaseItems = oneTimeBookingItems.map(o => createPurchaseItem(o, 1, appContext));
    return applyRoundingCorrection({
        amounts: updateAmounts(booking.priceInfo.oneTime.amounts, oneTimePurchaseItems, appContext, additive),
        purchaseItems: additive
            ? booking.priceInfo.oneTime.purchaseItems.concat(oneTimePurchaseItems)
            : oneTimePurchaseItems
    }, appContext);
};

const createPerMonthPriceInfo = (booking, bookingItems, appContext, additive) => {
    const perMonthBookingItems = bookingItems.filter(o => o.paymentRecurrence === paymentRecurrences.perMonth.key);
    const perMonthPurchaseItems = perMonthBookingItems.map(o => createPurchaseItem(o, 1, appContext));
    return applyRoundingCorrection({
        amounts: updateAmounts(booking.priceInfo.perMonth.amounts, perMonthPurchaseItems, appContext, additive),
        purchaseItems: additive
            ? booking.priceInfo.perMonth.purchaseItems.concat(perMonthPurchaseItems)
            : perMonthPurchaseItems
    }, appContext);
};

const createWholePeriodPriceInfo = (booking, bookingItems, appContext, additive) => {
    if(booking.subscriptionBooking) {
        return undefined;
    }
    const days = differenceInDays(parseIsoDate(booking.endDate), parseIsoDate(booking.startDate)) + 1;
    const wholePeriodBookingItems = bookingItems.filter(o => o.paymentRecurrence === paymentRecurrences.perMonth.key);
    const wholePeriodPurchaseItems = wholePeriodBookingItems.map(o => createPurchaseItem(o, days / daysPerMonth, appContext));
    const oneTimeBookingItems = bookingItems.filter(o => o.paymentRecurrence === paymentRecurrences.oneTime.key);
    const oneTimePurchaseItems = oneTimeBookingItems.map(o => createPurchaseItem(o, 1, appContext));
    const purchaseItems = wholePeriodPurchaseItems.concat(oneTimePurchaseItems);
    return applyRoundingCorrection({
        amounts: updateAmounts(booking.priceInfo.wholePeriod.amounts, purchaseItems, appContext, additive),
        purchaseItems: additive
            ? booking.priceInfo.wholePeriod.purchaseItems.concat(purchaseItems)
            : purchaseItems
    }, appContext);
};

const createPurchaseItem = (bookingItem, factor, appContext) => {
    const amount = roundAmount(factor * bookingItem.amount, appContext);
    return {
        amount,
        currency: bookingItem.currency,
        description: bookingItem.description,
        type: bookingItem.type,
        vat: calculateVat(amount, bookingItem.vatRate, appContext),
        vatRate: bookingItem.vatRate,
        commissionRate: bookingItem.commissionRate
    };
};

const updateAmounts = (amounts, purchaseItems, appContext, additive) => {
    const updatedAmounts = {
        additionalServicesAmount: roundAmount((additive ? amounts.additionalServicesAmount : 0) + getFilteredSum(purchaseItems, o => o.amount, o => o.type !== purchaseItemTypes.storage.key), appContext),
        additionalServicesVat: roundAmount((additive ? amounts.additionalServicesVat : 0) + getFilteredSum(purchaseItems, o => o.vat ?? 0, o => o.type !== purchaseItemTypes.storage.key), appContext),
        storageAmount: roundAmount((additive ? amounts.storageAmount : 0) + getFilteredSum(purchaseItems, o => o.amount, o => o.type === purchaseItemTypes.storage.key), appContext),
        storageVat: roundAmount((additive ? amounts.storageVat : 0) + getFilteredSum(purchaseItems, o => o.vat ?? 0, o => o.type === purchaseItemTypes.storage.key), appContext),
        totalAmount: roundAmount((additive ? amounts.totalAmount : 0) + getFilteredSum(purchaseItems, o => o.amount), appContext),
        totalVat: roundAmount((additive ? amounts.totalVat : 0) + getFilteredSum(purchaseItems, o => o.vat ?? 0), appContext)
    };
    return updatedAmounts;
};

const getFilteredSum = (purchaseItems, valueSelector, filter) => calculateSum(purchaseItems.filter(o => !filter || filter(o)), valueSelector);

const applyRoundingCorrection = (priceInfo, appContext) => {
    const sum = priceInfo.amounts.totalAmount + priceInfo.amounts.totalVat;
    const roundedAmount = roundAmount(sum, appContext, true /* useDisplayPriceRounding */);
    const clonedPriceInfo = {
        amounts: { ...priceInfo.amounts },
        purchaseItems: priceInfo.purchaseItems.filter(o => o.type !== purchaseItemTypes.roundingCorrection.key),
    };

    clonedPriceInfo.amounts.roundingCorrection = roundedAmount - sum;
    if(clonedPriceInfo.amounts.roundingCorrection) {
        clonedPriceInfo.purchaseItems.push(createRoundingCorrectionPurchaseItem(clonedPriceInfo.amounts.roundingCorrection, appContext));
    }
    return clonedPriceInfo;
};
